 div {
	font-family: 'Calibri';
}

.app {
	height: 100vh;
	width: 100vw;
	background-color: #F1F1F1;
}

.appContainer {
	display: flex;
	flex-direction: row;
	max-width: 2600px;
	margin: auto
}
