.app {
	height: 100vh;
	width: 100vw;
	background-color: #F1F1F1;
}

.appContainer {
	display: flex;
	flex-direction: row;
	max-width: 2600px;
	margin: auto
}

.rightPaneAbout {
	background-color: #FFF;
	width: 25%;
    min-width: 500px;
}

.leftPaneAbout {
	background-color: #F1F1F1;
	width: 75%;
}

.innerPanel {
	/*background-color: #333;*/
	margin: 40px;
	height: calc(100vh - 80px);
	display: flex;
  	flex-direction: column;
}

.left {
	margin-left: 80px;
}

.right {
	margin-right: 80px;
}

.menuTextAbout {
    text-transform: uppercase;
    margin: auto auto auto 0;
    opacity: 0;
    transition: 0.3s;
    color: #953f2d;
}

.menuTextHoverAbout {
    opacity: 1;
}

.logoAbout, .menuAbout {
	width: 100px;
	height: 100px;
	transition: .3s ease;
}

.logoAbout {
    margin-right: 50px;
    transition: 0.3s ease;
}

.logoAbout:hover {
    opacity: 0.6;
}

.menuAbout:hover {
    opacity: unset;
}

.menuLinkAbout {
    margin-left: 0;
	margin-right: 50px;
	width: 100px;
}

.textAbout {
	font-weight: 400;
	font-size: 24px;
}

.contentBlockAbout {
	width: 70%;
	margin: auto auto auto auto;
	text-align: center;
}

.selfieContentContainer {
	justify-content: start;
	padding-right: 0;
	height: auto;
	margin: auto;
}

.selfie {
	display: block;
	width: auto;
	height: auto;
	max-width: 90%;
	max-height: 90%;
	object-fit: cover;
	margin-left: auto;
	margin-right: auto;
}

.selfieText {
	margin-left: auto;
	margin-right: auto;
	font-style: italic;
}