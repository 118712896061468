.app {
	height: 100vh;
	width: 100vw;
	background-color: #F1F1F1;
}

.appContainer {
	display: flex;
	flex-direction: row;
	max-width: 2600px;
	margin: auto
}

.leftPane {
	background-color: #FFF;
	width: 75%;
    min-width: 500px;
}

.rightPane {
	background-color: #F1F1F1;
	width: 25%;
	min-width: 500px;
}

.innerPanel {
	/*background-color: #333;*/
	margin: 40px;
	height: calc(100vh - 80px);
	display: flex;
  	flex-direction: column;
}

.left {
	margin-left: 80px;
}
.right {
	margin-right: 80px;
}

.logo, .menu {
	width: 100px;
	height: 100px;
	transition: .3s ease;
}

.logoReturn {
	cursor: pointer;
}

.menu:hover {
	opacity: 0.6;
}

.arrowIcon {
	width: 30px;
	margin-left: 4px;
}

.contactOuterContainer {
	width: 60%;
	max-width: 500px;
	margin: auto;
	margin-top: 25px;
}

.contactRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.contactTitleContainer {
	text-align: center;
	margin-top: 10px;
}

.contactTitle {
	text-transform: uppercase;
	color: #953f2d;
	/*transition: .3s ease;*/
}

.contactTitleNone {
	opacity: 0;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.contactIcon {
	width: 40px;
	height: 40px;
	transition: .5s ease;
}

.contactIconNotSelected {
	opacity: 0.3;
}

.menuContainer {
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
}

.menuContainerList {
	max-width: 35%;
	min-width: 400px;
}

.menuLink {
    margin-left: 50px;
}

.contentContainer {
	height: calc(100vh - 200px);
	display: flex;
  	flex-direction: column;
	justify-content: space-between;
	padding-right: 20px;
}

.contentRight {
	text-align: right;
	height: 70%;
	max-height: 800px;
	margin: 0 0 auto auto;
}

.contentBlock {
	width: 70%;
	margin: auto 20% auto auto;
	text-align: center;
}

.introBlock {
	display: flex;
  	flex-direction: column;
	text-align: center;
}

.worksList {
	display: flex;
  	flex-direction: column;
	justify-content: space-between;
	height: 90%;
}

.text {
	font-weight: 400;
	font-size: 36px;
}

.textBold {
	font-weight: 700;
}

.textWorkLabel {
	transition: 0.3s ease;
	cursor: pointer;
}

.textNotSelected {
	opacity: 0.2;
}

.textTitle {
	font-size: 60px;
	font-weight: 600;
	margin-bottom: 20px;
}

.textBold {
	font-weight: 700;
}

.textHighlight {
	color: #FFF;
	background-color: #ec6f00;
	padding: 2px 8px 2px 8px;
	min-width: min-content;
	margin: auto;
}

.textHighlightBlue {
	background-color: #1696b6;
}

.textSubtitle {
	font-size: 22px;
	color: #953f2d;
	text-transform: uppercase;
}

.textSubtitleBottom {
    margin-top: 10%;
}

.menuText {
    text-transform: uppercase;
    margin: auto 0 auto auto;
    opacity: 0;
    transition: 0.3s ease;
    color: #953f2d;
}

.menuTextHover {
    opacity: 1;
}

a:active, a:focus, .menu:active, .menu:focus, .logo:active, .logo:focus {
	outline: 0;
	border: none;
	-moz-outline-style: none;
  }

  .contentContainerSelected {
	overflow-y: auto;
  }

  .contentSection {
	max-width: 1000px;
	align-self: center;
	margin-bottom: 40px;
  }

  .contentOverview {
	background-color: #F1F1F1;
	border-radius: 10px;
	padding: 30px;
	margin-top: 20px;
  }

  .contentText {
	display: block;
	font-size: 22px;
	margin-bottom: 40px;
  }

  .contentTextTitle {
	font-size: 28px;
	font-weight: 900;
	margin-bottom: -5px;
  }

  .contentTextLabel {
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #999;
	font-size: 16px;
	margin-bottom: 0;
  }

  .contentTextDate {
	font-style: italic;
	color: #999;
  }

  .contentTextOverview {
	line-height: 40px;
	margin-bottom: 20px;
  }

  .contentSectionTitle {
	font-weight: 900;
	font-size: 22px;
	margin-bottom: 10px;
  }

  .contentSectionParagraph {
	line-height: 40px;
	font-size: 18px;
	margin-bottom: 40px;
  }

  .contentImage {
	width: 100%;
  }

  .contentAnimation {
	width: 200px;
	display: block;
	margin: auto;
  }

  .contentLink {
	color: #1696b6;
	text-decoration: underline;
	text-decoration-color: #1696b6;
  }

  .contentLink:hover {
	color: #ec6f00;
	text-decoration-color: #ec6f00;
  }
